import Layout from "../containers/layout";
import React from "react";
import SEO from "../components/seo";

import '../styles/guests.css';

const GuestSubmissions = (props) => {
    return (
        <Layout>
            <SEO title="Patronicity resources guest submissions guidelines" />
            <div class="guest-submissions content-page-wrap">
                <h1> Guest Contributors Submission Guidelines</h1>
                <p>We are now accepting submissions from guest contributors for <a href="https://resources.patronicity.com/">Patronicity Resources</a>. Patronicity is excited to work with contributing writers to address the topics that matter most to our audience and promote vibrant communities.</p>
                <p>Pitches should align with Patronicity’s mission to build vibrant communities, be people-first, and consider triple bottom line values. </p>
                <h3>Topics</h3>
                <p><b>Crowdfunding</b> is the process of gathering a community to provide financial support for a project within a set period of time. Our crowdfunding platform specializes in community-based projects that empower communities to transform and invest in themselves. Our crowdfunding resources help our audience better understand the process and learn best practices.</p>
                <p><b>Crowdgranting</b> is our unique model that combines crowdfunding with a matching grant from a partnering organization. This section shares how communities are implementing crowdgranting to reach their community and economic development goals. In addition, this section provides best practices for grantmaking and community giving programs. </p>
                <p><b>Placemaking</b> aims to develop public spaces, through a community engagement process, to improve quality of life and quality of place. We help communities unlock potential in the ordinary by helping them take a second look at neglected and underused spaces. Our placemaking resources share inspiration, best practices, and the latest trends in the field.</p>
                <p><b>People & Places</b> shares success stories and inspiration from individuals building vibrant communities. This section includes case studies and our long-running Patron in the City feature.</p>
                <h3>Submissions</h3>
                <p>Email article pitches to <a href="mailto:maddie@patronicity.com">maddie@patronicity.com</a>. Please provide a one paragraph description of what you would like to write about and your timeline for completing the article. Include a brief author bio with your pitch to provide context on who you are and why you are qualified to write on the topic. All submission information should be provided in the body of the email. Attachments will not be reviewed. Submissions should not be a sales pitch for a product or service. We allow already published content. We do not offer payment for article submissions. </p>
            </div>
        </Layout>
    )
};

export default GuestSubmissions;
